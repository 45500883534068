import * as React from 'react';

import Header from '../components/header';
import Container from '../components/container';
import Link from '../components/link';
import Empty from '../components/empty';
import SEO from '../components/seo';
import PostMeta from '../components/post-meta';
import Footer from '../components/footer';

const PostContent = ({ post, index = 0 }) => {
  const { frontmatter, timeToRead } = post;
  const { title, description, slug } = frontmatter;

  return (
    <article className="blog-post--single">
      <PostMeta frontmatter={frontmatter} readTime={timeToRead} />
      <Link className="link-clear link-hover" to={slug}>
        <h2>{title}</h2>
      </Link>
      <p>{description}</p>
    </article>
  );
};

const BlogPostsTemplate = ({ pageContext }) => {
  const { posts } = pageContext;
  const hasPosts = posts.length > 0;

  return (
    <>
      <SEO
        title="Ramo Mujagic - Blog"
        description="Place where I share my thoughts and knowledge about, probably, everything"
      />

      <Header
        title="Yet another, ...blog"
        description="My digital playground to scribble and babble about all sorts of stuff."
      />

      <Container className={hasPosts ? '' : 'v-center h-center'}>
        <main className="blog-posts">
          {hasPosts ? (
            <div className="grid">
              {posts.map((post, index) => (
                <PostContent
                  key={post.node.id}
                  post={post.node}
                  index={index}
                />
              ))}
            </div>
          ) : (
            <Empty />
          )}
        </main>
      </Container>

      <Footer />
    </>
  );
};

export default BlogPostsTemplate;
